:root {
    --bs-primary-dam: #202020 !important;
    --bs-secondary-dam: #d5a770 !important;
  }

.c-pointer{
    cursor: pointer;
}

.bg-primary-dam{
    background-color: var(--bs-primary-dam);
    color: var(--bs-white);
}

.bg-secondary-dam{
    background-color: var(--bs-secondary-dam);
}

.btn-primary-dam{
    border-color: var(--bs-white);
    border-width: 1px;
    background-color: var(--bs-primary-dam);
    color: var(--bs-white);
}

.btn-primary-dam:hover{
    color:  var(--bs-secondary-dam);
    background-color: var(--bs-primary-dam);
}

.btn-secondary-dam{
    border-width: 1px;
    background-color: var(--bs-black);
    color: var(--bs-white);
}

.btn-secondary-dam:hover{
    background-color: var(--bs-black);
    color:  var(--bs-secondary-dam);
}

.btn-outline-secondary-dam{
    border-color: var(--bs-secondary-dam);
    
}
.btn-outline-secondary-dam:hover{
    border-color: var(--bs-white);
    color:  var(--bs-white) !important;
    
}

.card{
    border-color:  var(--bs-black);
}

.form-control, .form-select, .form-check-input{
  border-color:  var(--bs-black);
  border-width: 1px;
}

.form-control:focus, .form-select:focus, .form-check-input:focus{
  border-color: var(--bs-secondary-dam) !important;
  box-shadow: 2px 2px 2px var(--bs-secondary-dam) !important; 
}

.hover-black:hover{
    color:  var(--bs-black);
    text-decoration-color:var(--bs-black) !important;
}

.hover-white:hover{
    color:  var(--bs-white);
    text-decoration-color:var(--bs-white) !important;
}

.hover-secondary-dam:hover{
    color:  var(--bs-secondary-dam)!important;
}


.text-secondary-dam{
    color: var(--bs-secondary-dam) !important;
}

.text-primary-dam{
    color: var(--bs-primary-dam);
}




/* Para pantallas más pequeñas que sm */
@media (max-width: 575.98px) {
    .contenedor-img-card {
      height: 450px; /* Ajusta la altura para pantallas pequeñas */
    }
    .img-resumen {
        width: 80px;
        height: 260px;
    }
  }
  
  /* Para pantallas entre sm y md */
  @media (min-width: 576px) and (max-width: 767.98px) {
    .contenedor-img-card {
      height: 450px; /* Ajusta la altura para pantallas medianas */
    }
    .img-resumen {
        width: 80px;
        height: 160px;
    }
  }
  
  /* Para pantallas entre md y lg */
  @media (min-width: 768px) and (max-width: 991.98px) {
    .contenedor-img-card {
      height: 400px; /* Ajusta la altura para pantallas grandes */
    }
  }
  
  /* Para pantallas entre lg y xl */
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .contenedor-img-card {
      height: 400px; /* Ajusta la altura para pantallas extra grandes */
    }
  }
  
  /* Para pantallas xl o más grandes */
  @media (min-width: 1200px) {
    .contenedor-img-card{
      height: 500px; /* Ajusta la altura para pantallas extra extra grandes */
    }
  }